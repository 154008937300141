import axios from "axios";
import { baseUrl, version, apiKey } from "./const";

const instance = axios.create({
	baseURL: `${baseUrl}/api/${version}/`,
	responseType: "json",
	headers: { 'x-api-key': apiKey }
});

export default instance;

import React from 'react';
import { InputRow } from '../InputRow/index';
import { InputGroup, FormControl } from 'react-bootstrap';
import { useDataContext } from '../../dataContext';

export const CompositeInput = ({ isRequired, label, optionsFirst, optionsSecond, onChange, tooltip }) => {

	const [state, dispatch] = useDataContext();
	let firstInitial = state.formData && state.formData.number ? state.formData.number : -1;
	let secondInitial = state.formData && state.formData.total ? state.formData.total : -1;
	const [first, setFirst] = React.useState(firstInitial);
	const [second, setSecond] = React.useState(secondInitial);

	const handleChange = (value, fn, label) => {
		fn(value);
		dispatch({ type: 'ADD_TO_FORMDATA', label: label, value: value });
	}

	React.useEffect(() => {
		if (first !== -1 && second !== -1) {
			onChange(true);
		} else { onChange(false) }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [first, second])
	return (
		<InputRow label={`${label}${isRequired ? '*' : ''}`} tooltip={`${tooltip}`}>
			<InputGroup>
				<FormControl as="select"
					value={first}
					onChange={e => handleChange(e.target.value, setFirst, 'number')}
					className={first === -1 && 'placeholder-active'}
				>
					<option disabled value={-1} key={-1}></option>
					{optionsFirst.map(
						(t, k) => <option value={t.key} key={k}>{t.label}</option>
					)}
				</FormControl>
				<InputGroup.Append>
					<InputGroup.Text>di</InputGroup.Text>
				</InputGroup.Append>
				<FormControl as="select"
					value={second}
					onChange={e => handleChange(e.target.value, setSecond, 'total')}
				>
					<option disabled value={-1} key={-1}></option>
					{optionsSecond.map(
						(t, k) => <option value={t.value} key={k}>{t.label}</option>
					)}
				</FormControl>
			</InputGroup>
		</InputRow>
	)
}
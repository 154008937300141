import React from 'react';
import { InputText } from '../InputText/index';
import { InputNumber } from '../InputNumber/index';
import { InputSelect } from '../InputSelect/index';
import { CompositeInput } from '../CompositeInput/index';
import { MultipleSelect } from '../MultipleSelect/index';

export const Step = ({ isActive, conf, setIsValid, isLast }) => {
	let validityArray = conf.map(
		(e) => !e.required
	)
	const [isValidArray, setIsValidArray] = React.useState(validityArray);

	const checkValidity = (isElementValid, isElementRequired, key) => {
		let elementValid = isElementValid || !isElementRequired;
		setIsValidArray([...isValidArray.slice(0, key), elementValid, ...isValidArray.slice(key + 1)]);
	}

	React.useEffect(() => {
		let isStepValid = isValidArray.reduce(
			(acc, curr) => acc && curr, true
		)
		if (isStepValid) { setIsValid(true); } else { setIsValid(false); }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isValidArray])

	return (
		isActive
			? conf.map(
				(v, k) => {
					let component;
					console.log(v)
					switch (v.inputType) {
						case "inputText":
							component = <InputText key={k} isRequired={v.required} label={v.label} formData={v.formData} onChange={(isValid) => checkValidity(isValid, v.required, k)} actionType={isLast ? 'ADD_VALUE' : 'ADD_TO_FORMDATA'} tooltip={v.tooltip} />;
							break;

						case "number":
							component = <InputNumber key={k} isRequired={v.required} label={v.label} formData={v.formData} onChange={(isValid) => checkValidity(isValid, v.required, k)} tooltip={v.tooltip} />;
							break;

						case "select":
							component = <InputSelect key={k} isRequired={v.required} label={v.label} options={v.formData.options} onChange={(isValid) => checkValidity(isValid, v.required, k)} actionType={isLast ? 'ADD_VALUE' : 'ADD_TO_FORMDATA'} tooltip={v.tooltip} />;
							break;

						case "composite":
							component = <CompositeInput key={k} isRequired={v.required} label={v.label} optionsFirst={v.formData.optionsFirst} optionsSecond={v.formData.optionsSecond} onChange={(isValid) => checkValidity(isValid, v.required, k)} tooltip={v.tooltip} />
							break;

						case "multipleChoice":
							component = <MultipleSelect key={k} isRequired={v.required} label={v.label} formData={v.formData} onChange={(isValid) => checkValidity(isValid, v.required, k)} tooltip={v.tooltip} />
							break;
						default:
							break;
					}
					return component;
				}
			)
			: null
	);
}
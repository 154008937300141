import React from 'react';
import { InputRow } from '../InputRow/index';
import { InputGroup, FormControl, Form } from 'react-bootstrap';
import { useDataContext } from '../../dataContext';
import './style.scss';

export const InputText = ({ isRequired, label, formData, onChange, actionType, tooltip }) => {
	const [state, dispatch] = useDataContext();
	const initial = actionType === 'ADD_VALUE' && state && state[formData.key]
		? state[formData.key]
		: actionType === 'ADD_TO_FORMDATA' && state.formData && state.formData[formData.key]
			? state.formData[formData.key]
			: "";
	const [value, setValue] = React.useState(initial);
	const [isValid, setIsValid] = React.useState(true);


	React.useEffect(() => {
		if (formData.type !== 'email' || (formData.type === 'email' && value.length > 3 && value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) {
			setIsValid(true);
			onChange(true);
			dispatch({ type: actionType, label: formData.key, value: value });
		} else {
			onChange(false);
			if (formData.type === 'email' && value.length > 0) {
				setIsValid(false)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<InputRow label={`${label}${isRequired ? '*' : ''}`} tooltip={`${tooltip}`}>
			<InputGroup>
				<FormControl
					type={formData.type}
					placeholder={formData.placeholder}
					value={value}
					onChange={(e) => setValue(formData.type === 'number' ? Number(e.target.value) : e.target.value)}
				/>
				{formData.textAfter && <InputGroup.Append>
					<InputGroup.Text>{formData.textAfter}</InputGroup.Text>
				</InputGroup.Append>}

			</InputGroup>
			{!isValid &&
				<Form.Text className="text-muted">Inserisci una mail valida.</Form.Text>}
		</InputRow>
	);
}
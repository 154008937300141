import React, {useState, useEffect, useRef} from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import './style.scss';
import {useContactModalLogic} from "./ContactModal.logic";
import { AiFillCloseCircle } from 'react-icons/ai';


export const ContactModal = () => {
    const {
        show,
        Select,
        handleShow,
        onSubmit,
        register,
        handleSubmit,
        errors,
        state
    } = useContactModalLogic();


    return (
        <>
            <Button variant="primary" onClick={handleShow} className="modal-button" >
                Contattaci
            </Button>
                <div className={show || Boolean(state.showContactForm)
                    ? 'show-slide-from-left menu-slide-from-left transition-after-pageload'
                    : 'menu-slide-from-left transition-after-pageload'} id="menu">
                    <div className="inner-menu-slide-from-left">
                        <div onClick={() => handleShow(false)}>
                            <span className="close-button"><AiFillCloseCircle /></span>
                            <div className="title-form">
                                <span className="title">Contattaci</span>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="text" style={{display: "none"}} {...register("botCheck")}/>

                            <Row className="mb-3">
                                <Form.Group as={Col} md={6} xs={12} controlId="contactForm.name">
                                    <Form.Label className="mb-1" >Nome*</Form.Label>
                                    <input className="form-control" {...register("name", { required: true })} />
                                    {errors.name && <span className='error-message'>Campo obbligatorio</span>}
                                </Form.Group>

                                <Form.Group as={Col} md={6} xs={12} controlId="contactForm.surname">
                                    <Form.Label className="mb-1" >Cognome*</Form.Label>
                                    <input className="form-control" {...register("surname", { required: true })} />
                                    {errors.surname && <span className='error-message'>Campo obbligatorio</span>}
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} md={6} xs={12} className="mb-4" controlId="contactForm.email">
                                    <Form.Label>Email*</Form.Label>
                                    <input className="form-control" {
                                        ...register("email",
                                            {
                                                required: true,
                                                pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i })
                                    } />
                                    {errors.email?.type === 'required' && <span className='error-message'>Campo obbligatorio</span>}
                                    {errors.email?.type === 'pattern' && <span className='error-message'>Formato Email non valido</span>}
                                </Form.Group>

                                <Form.Group as={Col} md={6} xs={12} className="mb-4" controlId="contactForm.company">
                                    <Form.Label className="mb-1" >Nome Azienda</Form.Label>
                                    <input className="form-control" {...register("company")} />
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} md={6} xs={12} className="mb-4" controlId="contactForm.number">
                                    <Form.Label className="mb-1" >Telefono*</Form.Label>
                                    <input type="number" className="form-control" {...register("phone", { required: true })} />
                                    {errors.phone?.type === 'required' && <span className='error-message'>Campo obbligatorio</span>}
                                </Form.Group>

                                <Select label="Tipologia*" {...register("typology", { required: true })} />

                            </Row>


                            <Form.Group className="mb-4" controlId="contactForm.message">
                                <Form.Label className="mb-1" >Messaggio*</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Scrivi un messaggio"
                                    {...register("message", { required: true })}
                                    style={{ height: '100px' }}
                                />
                                {errors.message?.type === 'required' && <span className='error-message'>Campo obbligatorio</span>}
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="contactForm.checkboxPrivacy">
                                <Form.Check type='checkbox' id="contactForm.checkboxPrivacy" >
                                    <Form.Check.Input type='checkbox' {...register("privacy", { required: true })}/>
                                    <Form.Check.Label>Accetto le condizioni descritte nella nostra <a href="https://www.siacasagroup.com/p/privacy.html" target="_blank">Privacy Policy</a></Form.Check.Label>
                                </Form.Check>
                                {errors.privacy?.type === 'required' && <span className='error-message'>Campo obbligatorio</span>}
                            </Form.Group>


                            <Button variant="primary" type="submit" id="buttonSubmit" className="contact-button">
                                Invia
                            </Button>
                        </form>
                    </div>
                </div>
        </>
    );
}

import React from 'react';
import { Hero } from '../../components/Hero/index';
import casa_valutazione_indirizzo from './../../assets/casa_valutazione_indirizzo.svg';
import Valuta_semplici_passi from './../../assets/Valuta_semplici_passi.svg';
import valutazione_immobiliare_completa from './../../assets/valutazione_immobiliare_completa.svg';
import Casa_caratteristiche from './../../assets/Casa_caratteristiche.svg';
import valutazione_immobiliare from './../../assets/valutazione_immobiliare.jpg';
import immagine_home_2 from './../../assets/home_image_2.jpg';
import comunicazione from './../../assets/comunicazione.jpg';
import professionisti from './../../assets/professionisti.jpg';
import team_affiatato from './../../assets/team_affiatato.jpg';
import supporto_al_cliente from './../../assets/supporto_al_cliente.jpg';
import './style.scss';
import { Button } from 'react-bootstrap';
import {ContactModal} from "../../components/ContactModal";
import {ServicesIcons} from "../../components/ServicesIcons";
import {useHomeLogic} from "./HomeLogic";

export const Home = () => {
	const {
		showContactForm
	} = useHomeLogic();

	return (
		<>
			<main>
				<ContactModal />
				<Hero />
				<div className="container">
					<section>
						<div className="row mb-4">
							<div className="col"></div>
							<div className="col-lg-12 col-md-12">
								<p className="highlight">
									<b>Siacasa è l’agenzia immobiliare</b> specializzata in <b>compravendite e locazioni di immobili in ambito Residenziale e Business.</b> Il nostro servizio di intermediazione completo e di alta qualità si focalizza in particolare sull’acquisizione di immobili residenziali e non residenziali nelle aree di <b>Milano, Monza e Brianza e Lecco</b>.
									<br/>
									A <b>Siacasa</b> si rivolgono sia i privati cittadini che i costruttori e le imprese edili, così come gli investitori immobiliarie le PMI che desiderano un partner trasparente, affidabile e preparato.
									<br/>
									<b>Desideri valutare il tuo immobile?</b> La valutazione immobiliare è il punto di partenza fondamentale per qualunque compravendita immobiliare. Utilizza il nostro <b>strumento digitale</b> per ricevere una <b>stima completamente gratuita</b>: è sufficiente inserire i dati dell’immobile da valutare e controllare, nell’arco di pochi minuti, la tua casella di posta elettronica.
									<br/>
									<b>Benvenuto in Siacasa.</b>
								</p>
							</div>
							<div className="col"></div>
						</div>
					</section>
					{/* <section>
						<div className="row my-5">
							<div className="col-md-4 col-sm-12 ico-box">
								<img src={valuta_casa_semplicemente} height="60" alt="Valuta la tua casa in modo semplice" />
								<h6>Semplice</h6>
								<p>Servono poche e semplici informazioni per compilare la scheda dell'immobile da valutare.</p>
							</div>
							<div className="col-md-4 col-sm-12 ico-box">
								<img src={Valuta_semplici_passi} height="60" alt="Valuta la tua casa in modo veloce" />
								<h6>Veloce</h6>
								<p>In soli due minuti e in pochi passaggi, puoi ottenere la stima del valore dell'immobile.</p>
							</div>
							<div className="col-md-4 col-sm-12 ico-box">
								<img src={valutazione_immobiliare_completa} height="60" alt="Valuta la tua casa in modo completo" />
								<h6>Completa</h6>
								<p>Hai un report con tuitti i dati utili per un'eventuale compravendita o affitto.</p>
							</div>
						</div>
					</section> */}
					<section>
						<div className="row my-5">
							<div className="col-lg-3 col-sm-12 ico-step">
								<div className="step-img">
									<div>
										<span>1</span>
										<img src={casa_valutazione_indirizzo} height="31" alt="Valuta la tua casa in modo semplice" />
									</div>
								</div>
								<div className="step-text">
									<h6>Indirizzo</h6>
									<p>Inserisci l'indirizzo completo dell'immobile che vuoi valutare.</p>
									<p>Es.: Via Albert Bruce Sabin, Cavenago di Brianza</p>
								</div>
							</div>
							<div className="col-lg-3 col-sm-12 ico-step">
								<div className="step-img">
									<div>
										<span>2</span>
										<img src={Casa_caratteristiche} height="31" alt="Valuta la tua casa in modo completo" />
									</div>
								</div>
								<div className="step-text">
									<h6>Tipologia</h6>
									<p>Scegli la tipologia dell'immobile: residenziale (appartementi, ville, attici...) o commerciale (negozi, capannoni)</p>
								</div>
							</div>
							<div className="col-lg-3 col-sm-12 ico-step">
								<div className="step-img">
									<div>
										<span>3</span>
										<img src={Valuta_semplici_passi} height="31" alt="Valuta la tua casa in modo veloce" />
									</div>
								</div>
								<div className="step-text">
									<h6>Caratteristiche</h6>
									<p>Descrivi al meglio l'immobile, pi<span className="alternate">ù</span> sei preciso e pi<span className="alternate">ù</span> l'algoritmo sarà puntuale.</p>
								</div>
							</div>

							<div className="col-lg-3 col-sm-12 ico-step">
								<div className="step-img">
									<div>
										<span>4</span>
										<img src={valutazione_immobiliare_completa} height="46" alt="Valuta la tua casa in modo completo" />
									</div>
								</div>
								<div className="step-text">
									<h6>Controlla la mail!</h6>
									<p>Inserisci la mail e controlla la tua casella di posta per trovare la tua valutazione.</p>
								</div>
							</div>
						</div>
					</section>
					<section>
						<div className="row my-5 grey-bg mx-0">
							<div className="col-md-12 col-lg-6 py-5 pl-5">
								<h3>Come funziona?</h3>
								<p>Con il nostro algoritmo di valutazione, puoi scoprire in pochi minuti quanto vale il tuo immobile. ​In <strong>pochi secondi</strong> siamo in grado di valutare casa tua e analizzare tantissimi dati tra i quali: </p>
								<ul>
									<li>pi<span className="alternate">ù</span> di 30 caratteristiche del tuo immobile (stato di conservazione, dimensioni ecc.),</li>
									<li>il prezzo di compravendita di altri immobili nella tua zona,</li>
									<li>gli immobili attualmente in vendita vicino al tuo immobile.</li>
								</ul>
								<p>Oltre a questo abbiamo dei <strong>referenti di zona disponibili per effettuare dei sopralluoghi</strong> di valutazione del tuo immobile. Il nostro algoritmo è sempre aggiornato e dotato di <strong>intelligenza artificiale</strong> che gli permette di migliorarsi nel tempo e di offrirti la migliore esperienza di valutazione immobile online. </p>
							</div>
							<div className="col-md-12 col-lg-6 pr-0 pl-0 pl-lg-3">
								<div>
									<img className="img-cover" src={valutazione_immobiliare} alt="Valuta la tua casa in modo completo" />
								</div>
							</div>
						</div>
						<div className="row my-5 grey-bg mx-0">
							<div className="col-md-12 col-lg-6 pr-0 pl-0 pl-lg-3">
								<div><img className="img-cover-2" src={immagine_home_2} alt="Valuta la tua casa in modo completo" /></div>
							</div>
							<div className="col-md-12 col-lg-6 py-5 pl-5">
								<h3>Perché scegliere Siacasa per la compravendita e l’acquisizione di immobili</h3>
								<h5>Sopralluogo con sessione fotografica professionale</h5>
								<p>Per presentare al meglio il tuo immobile, Siacasa mette a tua disposizione un fotografo professionista specializzato in immagini per il settore immobiliare. Il servizio è attivo su richiesta.</p>
								<h5>Verifica degli atti necessari alla vendita</h5>
								<p>A Siacasa piace vendere immobili senza pensieri: ecco perché ha incluso la verifica della documentazione tra i propri servizi. Ci avvaliamo della collaborazione dei migliori architetti, notai, avvocati e commercialisti per risolvere sul nascere qualunque problematica.</p>
								<h5>Valutazione trasparente del valore di mercato</h5>
								<p>L’affidabilità delle nostre stime? È il risultato del valutometro e della possibilità di accesso a una banca dati profilata per la compravendita di immobili. Siacasa opera nel settore da trent’anni e dispone dell’esperienza e degli strumenti più adeguati a fornire prezzi di mercato il più possibile aderenti ai prezzi di vendita. </p>
								<h5>Agenti Immobiliari abilitati</h5>
								<p>Tutti i clienti di Siacasa possono stare tranquilli, perché tutti i nostri operatori dispongono dell’abilitazione professionale. Onestà, trasparenza e chiarezza sono al centro della nostra proposta.
								</p>
								<Button
									variant="primary"
									type="button"
									id="buttonSubmitContactForm"
									className="contact-button"
									onClick={showContactForm}>
									Richiedi una consulenza
								</Button>
							</div>
						</div>
						<div className="row my-5">
							<div className="col-12 text-center pt-5">
								<h3>Chi siamo</h3>
								<p>
									Fondata dal suo titolare Domenico Tronca, <strong>l'agenzia Siacasa Immobiliare</strong>, con la sua esperienza ultraventennale, opera in Lombardia con una presenza determinante nelle province di Milano, Monza Brianza e Lecco. I nostri punti di forza:
								</p>
							</div>
							<div className="col-lg-3 col-12 col-md-6">
								<div className="img-round">
									<img src={supporto_al_cliente} alt="valutazione_casa_supporto_al_cliente" />
								</div>
								<p className="text-center">Grande <strong>attenzione</strong> <br />al cliente​</p>
							</div>
							<div className="col-lg-3 col-12 col-md-6">
								<div className="img-round">
									<img src={team_affiatato} alt="valuta_casa_geometri_broker" />
								</div>
								<p className="text-center">Agenti <strong>professionisti</strong> e professionali</p>
							</div>
							<div className="col-lg-3 col-12 col-md-6">
								<div className="img-round">
									<img src={professionisti} alt="valuta_immobile_professionisti" />
								</div>
								<p className="text-center">Un circuito di <strong>partner</strong><br /> affiatati</p>
							</div><div className="col-lg-3 col-12 col-md-6">
								<div className="img-round">
									<img src={comunicazione} alt="valutazione_casa_trasparenza" />
								</div>
								<p className="text-center"><strong>Trasparenza</strong><br /> nella comunicazione</p>
							</div>
						</div>
					</section>
					<section>
						<div className="row my-5">
							<div className="col-12 text-center pt-5">
								<h3>I nostri servizi</h3>
								<p></p>
								<br/>
							</div>
							<ServicesIcons/>
							<div className="col-6 offset-3 text-center pt-5">
								<Button
									variant="primary"
									type="button"
									id="buttonSubmitContactForm"
									className="contact-button"
									onClick={showContactForm}>
									Richiedi una consulenza
								</Button>
							</div>
						</div>
					</section>
				</div>
			</main>
			<footer>
				<div className="container">
					Copyright© {new Date().getFullYear()} SIACASA IMMOBILIARE S.R.L. - Via Enrico Berlinguer 44, Cornate d'Adda (MB) - <a href="https://www.siacasagroup.com/p/privacy.html" target="blank">Privacy</a> - <a href="https://www.siacasagroup.com/p/cookie-policy.html" target="blank">Cookie policy</a>
				</div>
			</footer>
		</>
	);
}
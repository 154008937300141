import React from 'react';
import { InputRow } from '../InputRow/index';
import { FormControl } from 'react-bootstrap';
import { useDataContext } from '../../dataContext';

export const InputSelect = ({ isRequired, label, options, onChange, actionType, tooltip }) => {
	const [state, dispatch] = useDataContext();
	const dataLabel = label.toLowerCase().replaceAll(" ", "_");
	let initialValue = actionType === 'ADD_VALUE' && state && state[dataLabel]
		? state[dataLabel]
		: actionType === 'ADD_TO_FORMDATA' && state.formData && state.formData[dataLabel]
			? state.formData[dataLabel]
			: -1;
	const [value, setValue] = React.useState(initialValue);
	const handleChange = (value) => {
		setValue(value);
		dispatch({ type: actionType, label: dataLabel, value: value });
		if (value !== -1) {
			onChange(true);
		} else { onChange(false) }
	}
	return (
		<InputRow label={`${label}${isRequired ? '*' : ''}`} tooltip={`${tooltip}`}>
			<FormControl as="select"
				value={value}
				onChange={e => handleChange(e.target.value)}
				className={value === -1 && 'placeholder-active'}
			>
				<option disabled key={-1} value={-1}>Seleziona...</option>
				{options.map(
					(e, k) => <option key={k} value={e.key}>{e.label}</option>
				)}
			</FormControl>
		</InputRow>
	)
}
import React from 'react';
import './style.scss';

export const Stepper = ({ children, activeKey }) => {
	return (
		<>
			<ul className={`stepper-container step-active-${activeKey}`}>
				{children.map(
					(child, key) => React.cloneElement(child, { isActive: Number(activeKey) === (key + 1) })
				)}
			</ul>
		</>
	);
}


export const StepLabel = ({
	isActive,
	label
}) => {
	return (
		<li className={`step ${isActive ? "active" : ""}`}>
			{label}
		</li>
	);
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { DataContextProvider } from './dataContext';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-TXLJ3S4'
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
	<React.StrictMode>
		<DataContextProvider>
			<App />
		</DataContextProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

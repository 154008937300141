import React from 'react';

const DataContext = React.createContext();

function DataReducer(state, action) {
	switch (action.type) {
		case 'ADD_VALUE':
			return { ...state, [action.label]: action.value };
		case 'ADD_TO_FORMDATA':
			return { ...state, formData: { ...state.formData, [action.label]: action.value } };
		case 'PREPARE_TO_SEND':
			return { ...state, ...state.geocode };
		case 'DELETE':
			return {...initialState};
		case 'SHOW_CONTACT_FORM':
			return { ...state, showContactForm: action.value };
		default:
			throw new Error(`Unhandled action type: ${action.type}`)
	}
}

const initialState = {showContactForm: false};

export function DataContextProvider(props) {
	const [data, dispatch] = React.useReducer(DataReducer, initialState);
	return <DataContext.Provider value={[data, dispatch]} {...props} />
}

export function useDataContext() {
	const context = React.useContext(DataContext)
	if (!context) {
		throw new Error(
			'DataContext must be used within a DataContextProvider',
		)
	}
	return context;
}
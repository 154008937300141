import logo from './assets/logo.png';
import { FormContainer } from './views/FormContainer';
import { Home } from './views/Home/index';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { useDataContext } from './dataContext';
import {Topbar} from "./components/Topbar";
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

function App() {
  const [data] = useDataContext();
  return (
    <Router>
      <header>
        <Topbar />
        <div className="container position-relative">
          <div className="logo-container">
            <Link to="/"><img src={logo} className="logo" alt="logo" height="60" /></Link>
            {/*<div className='contact-icons'>
              <a href="https://www.facebook.com/SiacasaImmobiliareSrl" target="_blank"><FaFacebook /></a>
              <a href="https://www.instagram.com/siacasa_immobiliare/" target="_blank"><FaInstagram /></a>
              <a href="https://www.linkedin.com/company/siacasa-immobiliare/" target="_blank"><FaLinkedin/></a>
              <a href="tel:0396840942">Tel: 039 68 40 942</a>
            </div>*/}
          </div>

          <div>


          </div>
        </div>
      </header>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/valuta">
          {data.geocode && data.type ? <FormContainer /> : <Redirect to="/" />}
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

import React from 'react';
import './style.scss';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';


export const Topbar = () => {
    return (
        <nav className="top-bar">
            <div className="top-bar-left nav-desktop">
                <ul className="menu link-tel">
                    <li>
                        <a href="tel:0396840942">
                            Tel: 039 68 40 942
                        </a>
                    </li>
                    <li>
                        <a href="mailto:info@siacasagroup.com">
                            Email: info@siacasagroup.com
                        </a>
                    </li>
                </ul>
            </div>

            <div className="top-bar-right nav-desktop">
                <ul className="menu menu-desktop">
                    <li><a href="https://www.facebook.com/SiacasaImmobiliareSrl" target="_blank"><FaFacebook /></a></li>
                    <li><a href="https://www.instagram.com/siacasa_immobiliare/" target="_blank"><FaInstagram /></a></li>
                    <li><a href="https://www.linkedin.com/company/siacasa-immobiliare/" target="_blank"><FaLinkedin/></a></li>
                </ul>
            </div>
        </nav>
    );
}

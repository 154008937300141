import React from 'react';
import { useDataContext } from '../../dataContext';
import { InputRow } from '../InputRow/index';
import { ButtonGroup, Button, InputGroup, FormControl } from 'react-bootstrap';
import './style.scss';

export const MultipleSelect = ({ isRequired, label, formData, onChange, tooltip }) => {
	const [state, dispatch] = useDataContext();
	let dataLabel = label.toLowerCase().replaceAll(/\s|\//g, "_");

	const setInitialValue = () => {
		console.log(state.formData[dataLabel])
		if (formData.type === "multiple" && state.formData[dataLabel]) return [...state.formData[dataLabel]];
		if (formData.type === "multiple" && !state.formData[dataLabel]) return [];
		if (formData.type === "boolean" && state.formData[dataLabel]) {
			let dataValue = formData.options.find((e) => e.key === state.formData[dataLabel]);
			let isChecked = dataValue ? dataValue.label : null;
			return { isChecked: isChecked, key: state.formData[dataLabel] }
		};
		if (formData.type === "boolean" && !state.formData[dataLabel]) return { isChecked: null, key: "" };
	}

	const initialValue = setInitialValue();
	const initialTextValue = state.formData[`${dataLabel}_text`] ? state.formData[`${dataLabel}_text`] : "";
	const [value, setValue] = React.useState(initialValue);
	const [isVisible, setIsVisible] = React.useState(false);
	const [textValue, setTextValue] = React.useState(initialTextValue);

	const handleClick = (v, l) => {
		if (formData.type === "multiple" && value.some(e => e === v)) {
			setValue(value.filter(e => e !== v));
		} else if (formData.type === "multiple") {
			setValue([...value, v])
		} else {
			setValue({ ...value, isChecked: l, key: v })
		}
	}

	const handleChange = (value) => {
		setTextValue(value);
		dispatch({ type: 'ADD_TO_FORMDATA', label: `${dataLabel}_text`, value: value });
	}
	React.useEffect(() => {

		onChange(true);
		if (formData.type === "boolean" && value.isChecked && (value.isChecked.toLowerCase() === "si" || value.isChecked.toLowerCase() === "sì")) {
			setIsVisible(true);
		} else { setIsVisible(false) };

		let toMemorize = formData.type === "boolean" ? value.key : value
		dispatch({
			type: 'ADD_TO_FORMDATA', label: dataLabel, value: toMemorize
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (<InputRow label={`${label}${isRequired ? '*' : ''}`} tooltip={`${tooltip}`}>
		<div className="multiple-select">
			<ButtonGroup>
				{formData.options.map(
					(v, k) => <Button
						key={k}
						onClick={() => handleClick(v.key, v.label)}
						className={(formData.type === 'boolean' && value.key === v.key && 'is-active') || (formData.type === 'multiple' && value.some(e => e === v.key) && 'is-active')}
					><span className="alternate">{v.label}</span></Button>
				)}
			</ButtonGroup>
			{formData.hasTextInput && isVisible && <InputGroup>
				<FormControl
					value={textValue}
					onChange={(e) => handleChange(e.target.value)}
					className="ml-5"
				/>
				{formData.textAfter && <InputGroup.Append>
					<InputGroup.Text>{formData.textAfter}</InputGroup.Text>
				</InputGroup.Append>}
			</InputGroup>}
		</div>
	</InputRow>);
}
import React from 'react';
import './style.scss';
import {Row, Col, OverlayTrigger} from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';


function showTooltip(label, tooltip)
{
	if(tooltip === "undefined" || tooltip === 'null'){
		return label;
	} else {
		function SiaCasaTooltip() {
			return (
				<>
					<OverlayTrigger
						placement="top"
						overlay={
							<Tooltip id={`tooltip-top`} className={'tooltip-top'}>
								{tooltip}
							</Tooltip>
						}
					>
						<a className={'tooltip-link'} href={'#'}>{label} </a>
					</OverlayTrigger>
				</>
			);
		}

		return (<SiaCasaTooltip />);
	}
}

export const InputRow = ({ label, children, tooltip }) => {
	return (
		<Row>
			<Col xs={12} md={6} className="d-flex align-items-center">
				{showTooltip(label, tooltip)}
			</Col>
			<Col xs={12} md={6}>
				{children}
			</Col>
		</Row>
	)
}
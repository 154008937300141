import React from 'react';
import { Stepper, StepLabel } from '../../components/Stepper/index';
import './style.scss';
import { Container, Button } from 'react-bootstrap';
import { useDataContext } from '../../dataContext';
import axios from './../../config/axios'
import { Spinner } from '../../components/Spinner/index';
import { Step } from '../../components/Step/index';
import sent from '../../assets/sent.svg'
import errorImg from '../../assets/error.svg'
import { ErrorLine } from '../../components/ErrorLine';
import TagManager from 'react-gtm-module'

function StateReducer(state, action) {

	switch (action.type) {
		case 'NEXTSTEP':
			TagManager.dataLayer({
				dataLayer: {
					event: 'nextstep',
					activeStep: state.activeStep + 1
				}
			})


			return { ...state, activeStep: state.activeStep + 1, currentStepValid: false };
		case 'PREVSTEP':
			TagManager.dataLayer({
				dataLayer: {
					event: 'prevstep',
					activeStep: state.activeStep - 1
				}
			})
			return { ...state, activeStep: state.activeStep - 1 };
		case 'CHANGELOADING':
			return { ...state, isLoading: !state.isLoading };
		case 'SETVALIDITY':
			return { ...state, currentStepValid: action.isValid };
		case 'SETCONFIG':
			return { ...state, config: action.config };
		default:
			throw new Error(`Unhandled action type: ${action.type}`)
	}
}
export const FormContainer = () => {
	const [state, setState] = React.useState("form");
	const [data] = useDataContext();

	const tagManagerArgs = {
		dataLayer: {
			event: 'FormContainer.loaded',
			activeStep: 1,
			search: {
				address: data.geocode.label,
				type: data.type
			}
		}
	}

	TagManager.dataLayer(tagManagerArgs)
	return (
		<div className="main">
			<Container>
				<div className="form-container">
					{state === 'form' && <Form onSend={() => setState("result")} onError={() => setState("error")} />}
					{state === 'result' && <Result />}
					{state === 'error' && <ErrorLine />}
				</div>
			</Container>
		</div>
	);
}

const Form = ({ onSend, onError }) => {
	const initialState = {
		activeStep: 1,
		currentStepValid: false,
		isLoading: false,
		config: []
	};
	const [data, dispatch] = useDataContext();
	const [state, update] = React.useReducer(StateReducer, initialState);
	const loadConf = async () => {
		update({ type: 'CHANGELOADING' });
		try {
			const response = await axios.get(`/geo/geocode?query=${data.geocode.label}&type=${data.type}`);
			if (response.data.code === 200) {
				update({ type: 'SETCONFIG', config: response.data.steps });
				dispatch({ type: 'ADD_VALUE', label: 'token', value: response.data.token });
			}
			update({ type: 'CHANGELOADING' });
		} catch (err) {
			console.log(err);
			onError();
		}
	}

	React.useEffect(() => {
		loadConf();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<Stepper activeKey={state.activeStep}>
				<StepLabel key="1" label="Step 1" />
				<StepLabel key="2" label="Step 2" />
				<StepLabel key="3" label="Step 3" />
			</Stepper>
			<div className="form-content">
				<h1>{state.activeStep === 1 ? 'Dimensione dell\'immobile' : state.activeStep === 2 ? 'Descrizione dell\'immobile' : 'I tuoi dati'}</h1>
				{state.activeStep === 3 && <p>Lasciaci i tuoi dati per ricevere la valutazione.</p>}
				<div className="form-grid my-6">
					{state.isLoading
						? <Spinner />
						: <>
							{state.config.map(
								(s, k) => <Step key={k} isActive={k === (state.activeStep - 1)} conf={s} setIsValid={(d) => update({ type: 'SETVALIDITY', isValid: d })} isLast={k === state.config.length - 1} />
							)}
						</>}
				</div>
				{state.activeStep === 3 && <p className="small">Continuando accetti le nostre <a href="https://www.siacasagroup.com/p/privacy.html" target="blank">condizioni sulla privacy</a>.</p>}
				<div className="d-flex justify-content-end w-100">
					{state.activeStep > 1 && <Button onClick={() => update({ type: 'PREVSTEP' })} className="mr-3 back">Indietro</Button>}
					{state.config && state.activeStep < state.config.length && <Button onClick={() => update({ type: 'NEXTSTEP' })} disabled={!state.currentStepValid}>Avanti</Button>}
					{state.config && state.activeStep === state.config.length && <Button onClick={() => onSend()} disabled={!state.currentStepValid}>Invia</Button>}
				</div>
			</div>
		</>
	);
}

const Result = () => {
	const [data, dispatch] = useDataContext();
	const [isLoading, setisLoading] = React.useState(true);
	const [error, setError] = React.useState('');

	const send = async () => {
		dispatch({ type: 'PREPARE_TO_SEND' });
		try {
			const response = await axios.post(`/leads`, data);
			if (response.data.code === 200) {
				console.log(response)
			}
			setisLoading(false);
		} catch (err) {
			console.log(err)
			setisLoading(false);
			setError(err);
		}
	}

	const goToWebsite = () => window.location.href = "https://www.siacasagroup.com/p/contatti.html";

	const goToHome = () => dispatch({ type: 'DELETE' })

	React.useEffect(() => {
		send()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		isLoading
			? <div className="result-container loader">
				<Spinner />
				<p className="mt-4 highlight">Attendi, stiamo elaborando i tuoi dati.</p>
			</div>
			: !error
				? <div className="result-container text-center">
					<img src={sent} alt="" height="300" />
					<p className="highlight">
						Abbiamo inviato la valutazione del tuo immobile all'indirizzo mail che ci hai lasciato
					</p>
					<p>Controlla la tua casella di posta, <b>anche lo spam.</b> <br /> Ti ricordiamo che la valutazione è frutto di un algoritmo, il valore verrà confermato dal nostro esperto dopo aver effettuato il sopralluogo.</p>
					<div className="mx-auto mt-4">
						<Button variant="primary" onClick={() => goToWebsite()} className="w-100">Contattaci</Button><br />
						<Button className="mt-3 w-100 back" onClick={() => goToHome()}>Nuova valutazione</Button>
					</div>
				</div>
				: <div className="result-container text-center">
					<img src={errorImg} alt="" height="300" />
					<p className="highlight">
						Ooooops qualcosa è andato storto!
					</p>
					<p>Abbiamo riscontrato un problema, riprova pi<span className="alternate">ù</span> tardi o mettiti in contatto con noi.</p>
					<div className="mx-auto mt-4">
						<Button variant="primary" onClick={() => goToWebsite()} className="w-100">Contattaci</Button><br />
						<Button className="mt-3 w-100 back" onClick={() => goToHome()}>Nuova valutazione</Button>
					</div>
				</div>
	);
}

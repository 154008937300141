import React, { useState } from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { InputRow } from '../InputRow/index';
import './style.scss';
import { useDataContext } from '../../dataContext';

export const InputNumber = ({ isRequired, label, formData, onChange, tooltip }) => {
	const [state, dispatch] = useDataContext();
	let initialValue = state.formData && state.formData[formData.key] ? state.formData[formData.key] : 1;
	const [number, setNumber] = useState(initialValue);

	const handleChange = (value) => {

		if (isNaN(Number(value)) || Number(value) < formData.min || Number(value) > formData.max) {
			onChange(false);
			return;
		}
		setNumber(value);
		dispatch({ type: 'ADD_TO_FORMDATA', label: formData.key, value: value });
		onChange(true);
	}

	const addToNumber = (value) => {
		let newValue = (Number(number) + value > formData.min && Number(number) + value < formData.max) ? (Number(number) + value).toString() : number;
		handleChange(newValue);
	}

	React.useEffect(() => {
		dispatch({ type: 'ADD_TO_FORMDATA', label: formData.key, value: initialValue });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<InputRow label={`${label}${isRequired ? '*' : ''}`} tooltip={`${tooltip}`}>
			<InputGroup>
				<InputGroup.Prepend>
					<Button onClick={() => addToNumber(-1)}> - </Button>
				</InputGroup.Prepend>
				<FormControl
					value={number}
					onChange={e => handleChange(e.target)}
					className="form-control-number"
				/>
				<InputGroup.Append>
					<Button onClick={() => addToNumber(1)}> + </Button>
				</InputGroup.Append>
			</InputGroup>
		</InputRow>
	);
}
import React from 'react'
import real_estate_service_icon from '../../assets/services/0-real-estate.svg';
import house_sign_service_icon from '../../assets/services/1-house-sign.svg';
import property_document_service_icon from '../../assets/services/2--property-document.svg';
import property_finding_service_icon from '../../assets/services/3-property-finding.svg';
import contract_service_icon from '../../assets/services/4-contract.svg';
import agreement_service_icon from '../../assets/services/5-agreement.svg';
import tax_law_consulting_service_icon from '../../assets/services/6-tax-law.svg';

export const ServicesIcons = () => {
    return (
        <div className="container-fluid">
            <div className="row my-5">
                <div className="col-lg-3 col-md-6">
                    <div className="service-item">
                        <div className="img-service">
                            <img width="100%" src={real_estate_service_icon} alt="Consulenza immobiliare in general"/>
                        </div>
                        <p className="text-center">Consulenza immobiliare in generale</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="service-item">
                        <div className="img-service">
                            <img width="100%" src={house_sign_service_icon}  alt="Compravendite e locazioni"/>
                        </div>
                        <p className="text-center">Compravendite e locazioni</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="service-item">
                        <div className="img-service">
                            <img width="100%" src={property_document_service_icon}  alt="Perizie immobiliari"/>
                        </div>
                        <p className="text-center">Perizie immobiliari</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="service-item">
                        <div className="img-service">
                            <img width="100%" src={property_finding_service_icon}  alt="Property finding"/>
                        </div>
                        <p className="text-center">Property finding</p>
                    </div>
                </div>
            </div>

            <div className="row my-5">
                <div className="col-lg-4 col-md-6">
                    <div className="service-item">
                        <div className="img-service">
                            <img width="100%" src={contract_service_icon}  alt="Assistenza per stesura e registrazione di contratti di locazione"/>
                        </div>
                        <p className="text-center">Assistenza per stesura e registrazione di contratti di locazione</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item">
                        <div className="img-service">
                            <img width="100%" src={agreement_service_icon}  alt="Assistenza per stesura preliminari di compravendita"/>
                        </div>
                        <p className="text-center">Assistenza per stesura preliminari di compravendita</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item">
                        <div className="img-service">
                            <img width="100%" src={tax_law_consulting_service_icon}  alt="Assistenza tecnico, fiscale e legale in ambito immobiliare con il supporto di Partner professionisti"/>
                        </div>
                        <p className="text-center">Assistenza tecnico, fiscale e legale in ambito immobiliare con il supporto di Partner professionisti</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, {useState, useEffect} from 'react';
import { Form, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import axios from "../../config/axios";
import Swal from 'sweetalert2';
import {useDataContext} from "../../dataContext";


export const useContactModalLogic = () => {
    const [show, setShow] = useState(false);
    const [state, dispatch] = useDataContext();
    const [appeared, setAppeared] = useState(false);
    const leftPanel = document.getElementById('menu');
    const formElements = document.querySelectorAll("#menu *");
    const [options, setOptions] = useState([]);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const Select = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
        <Form.Group as={Col} xs={12} md={6} controlId="contactForm.typology">
            <Form.Label className="mb-1" >{label}</Form.Label>

            <select className="form-control" name={name} ref={ref} onChange={onChange} onBlur={onBlur}>
                <option disabled value={-1} key={-1}>Seleziona la tipologia</option>
                {options.map(
                    (t, k) => <option value={t.value} key={k}>{t.label}</option>
                )}
            </select>
            {errors.typology?.type === 'required' && <span className='error-message'>Campo obbligatorio</span>}
        </Form.Group>
    ));

    const handleShow = (value) => {
        setShow(value);
        dispatch({ type: 'SHOW_CONTACT_FORM', value: false });
    };

    const handleScroll = () => {
        const position = window.scrollY;

        if(position > 800) {
            setAppeared(true);
            if(!appeared) {
                handleShow(true);
                dispatch({ type: 'SHOW_CONTACT_FORM', value: true });
            };
        }
    };

    const handleDocumentClick = (e) => {
        const isFormElement = Boolean(Array.from(formElements).find(node => node.isEqualNode(e.target)));

        if(e.target !== leftPanel &&
            !isFormElement &&
            show
        )
        {
            handleShow(false);
        }
    };

    const onSubmit = async data => {
        try {
            await axios.post(`/contacts`, {...data});
            Swal.fire({
                icon: 'success',
                title: 'Messaggio inviato con successo!',
                showConfirmButton: false,
                timer: 3500
            })
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: "Ops... Contattaci al \n 039 68 40 942",
                showConfirmButton: false,
                timer: 5500
            })
        }
        finally {
            handleShow(false);
            reset();
        }
    };



    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
       // window.addEventListener('click', handleDocumentClick);
        setOptions(JSON.parse(localStorage.getItem('tipologie') || []));

        // Remove the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
           // window.removeEventListener('click', handleDocumentClick);
        };
    }, [show, appeared]);

    return {
        show,
        setShow,
        appeared,
        setAppeared,
        leftPanel,
        formElements,
        setOptions,
        Select,
        handleShow,
        handleScroll,
        handleDocumentClick,
        onSubmit,
        register,
        handleSubmit,
        errors,
        options,
        state,
    };

};
import {useDataContext} from "../../dataContext";

export const useHomeLogic = () => {
    const [state, dispatch] = useDataContext();

    const showContactForm = () => {
        dispatch({ type: 'SHOW_CONTACT_FORM', value: true });
    }

    return {
        state,
        showContactForm
    };
}
import React, { useState, useEffect } from 'react';
import { FormControl, Button } from 'react-bootstrap';
import axios from './../../config/axios'
import './style.scss';
import { useDataContext } from '../../dataContext';
import { useHistory } from "react-router-dom";
import { Spinner } from '../Spinner/index';
import { ErrorLine } from '../ErrorLine/index';

export const Hero = () => {
	const [, dispatch] = useDataContext();
	const history = useHistory();
	const [complete, setComplete] = useState({ indirizzo: false, tipologia: false });
	const [error, setError] = useState(false);
	const handleClick = (e) => { e.preventDefault(); history.push('/valuta') };
	React.useEffect(() => {
		dispatch({ type: 'DELETE' })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<section>
			<div className="hero">
				<div>
					<div className="hero-title">
						<h1>Valuta casa con SiaCasa​</h1>
						<h2>Scopri il valore del tuo immobile in pochi minuti</h2>
					</div>
					<div className="hero-form">
						<Indirizzo onSelect={() => setComplete({ ...complete, indirizzo: true })} onError={() => setError(true)} />
						<Tipologia onSelect={() => setComplete({ ...complete, tipologia: true })} onError={() => setError(true)} />
						<Button variant="primary" disabled={!(complete.indirizzo && complete.tipologia)} onClick={(e) => handleClick(e)}>Valuta</Button>
					</div>
					{error && <ErrorLine />}
				</div>
			</div>
		</section>

	);
}

const Indirizzo = ({ onSelect, onError }) => {
	const [, dispatch] = useDataContext();
	const [geoCode, setGeoCode] = useState({ label: "", latitudine: "", longitudine: "" });
	const [query, setQuery] = useState("")
	const [options, setOptions] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	const loadAutocomplete = async (value) => {
		try {
			setOptions([])
			const response = await axios.get(`/geo/autocomplete?query=${value}&type=0`);
			if (response.data.code === 200 && response.data.data.length > 0) {
				setOptions(response.data.data);
			} else {
				setOptions('Nessun risultato')
			}
		} catch (err) {
			console.log(err)
			onError()
		}
	}

	const handleClick = (geo) => {
		setGeoCode({ ...geoCode, label: geo.label, latitudine: geo.latitudine, longitudine: geo.longitudine });
		setQuery(geo.label);
		dispatch({ type: 'ADD_VALUE', label: 'geocode', value: { ...geo } })
		onSelect();
	}

	useEffect(() => {
		if (query.length > 0 && query !== geoCode.label) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}

		if (query.length < 3 || query === geoCode.label) return;
		const timeOutId = setTimeout(() => loadAutocomplete(query), 300);
		return () => clearTimeout(timeOutId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	return (
		<div className={`hero-indirizzo ${isOpen && 'is-open'}`}>
			<div className="hero-prepend">
			</div>
			<FormControl
				placeholder="Es.: Via Albert Bruce Sabin, Cavenago di Brianza"
				value={query}
				onChange={(e) => setQuery(e.target.value)}
			/>
			{isOpen &&
				<div className="autocomplete">
					<ul>
						{Array.isArray(options) && options.length > 0
							? options.map((opt, k) => <li key={k} onClick={() => handleClick(opt)}>{opt.label}</li>)
							: options === 'Nessun risultato'
								? <li className="no-hover">{options}</li>
								: <li className="no-hover"><Spinner /></li>
						}
					</ul>
				</div>
			}
		</div>
	);
}

const Tipologia = ({ onSelect, onError }) => {
	const [, dispatch] = useDataContext();
	const [tipologia, setTipologia] = useState(-1);
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	let date = new Date()
	const tipologie = [{ "label": "Appartamento", "value": 0 }, { "label": "Villa", "value": 1 }, { "label": "Negozio", "value": 2 }, { "label": "Ufficio", "value": 3 }, { "label": "Capannone/Laboratorio", "value": 4 }];
	localStorage.setItem('tipologie', JSON.stringify(tipologie));
	localStorage.setItem('date_exp_tipologie', date.setDate(date.getDate() + 7));

	const loadTipologie = async () => {
		try {
			setIsLoading(true);
			const response = await axios.get('/tipologie');
			if (response.data.code === 200) {
				setOptions(response.data.data);
				let date = new Date()
				localStorage.setItem('tipologie', JSON.stringify(response.data.data));
				localStorage.setItem('date_exp_tipologie', date.setDate(date.getDate() + 7));
				setIsLoading(false);
			}
		} catch (err) {
			setIsLoading(false);
			onError()
			console.log(err)
		}
	}

	const handleChange = (value) => {
		setTipologia(value);
		dispatch({ type: 'ADD_VALUE', label: 'type', value: value })
		onSelect();
	}

	useEffect(async () => {
		// let today = new Date();
		// let expDate = localStorage.getItem('date_exp_tipologie') ? new Date(Number(localStorage.getItem('date_exp_tipologie'))) : false;

		/*if (localStorage.getItem('tipologie') && expDate && today < expDate) {
			setOptions(JSON.parse(localStorage.getItem('tipologie')))
		} else {

		}*/

	}, []);
	return (
		<div className="hero-tipologia">
			<div className="hero-prepend">
			</div>
			<FormControl as="select"
				value={tipologia}
				onChange={e => handleChange(e.target.value)}
				className={tipologia === -1 && 'placeholder-active'}
				disabled={isLoading}
			>
				<option disabled value={-1} key={-1}> Seleziona la tipologia</option>
				{tipologie.map(
					(t, k) => <option value={t.value} key={k}>{t.label}</option>
				)}
			</FormControl>
		</div>
	);
}